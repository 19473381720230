import React from 'react';
import './item-specs.styles.scss';

const ItemSpecs = ({ attributes }) => (
  <div className="table-container">
    <table>
      <tbody>
        {Object.keys(attributes).map((key, index) => (
          <tr key={key}>
            <td>{key.replace("_", " ")}</td>
            <td>{attributes[key]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ItemSpecs;

import "./search-card.styles.scss";
import { Link } from "react-router-dom";

const SearchCard = ({ product, onClickHandler }) => {
  const { imageUrl, price, name, length, basePrice } = product;
  
  return (
      <div className="search-card-container">
    <Link to={`/items/${name.replaceAll(" ", "_")}`} className="link" onClick={() => onClickHandler()}>
        <div className="product-image">
          <img src={imageUrl.replace("www", "b2b")} alt={name} />
        </div>
        <div className="product-name">{name}</div>
        <div className="product-price">
          {((basePrice ? basePrice : 0) + price).toFixed(2)}€
          {!basePrice && length && length.length > 1 ? "/m" : ""}
        </div>
    </Link>
      </div>
  );
};

export default SearchCard;

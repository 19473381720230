import "./burger-menu.styles.scss";
import Footer from "../footer/footer.component";

const BurgerMenu = ({ children, onToggle }) => {
  return (
    <div className="burger-menu">
      <div className="menu-container">
      <div className="spacer" />
        {children}
        <div className="burger-footer-container">
        <Footer />
        </div>
        {/* <div className="nav-links" onClick={onToggle}>
          <Link className="nav-link" to="/shop">
            VSI IZDELKI
          </Link>
          <Link className="nav-link" to="/contact">
            KONTAKT
          </Link>
        </div> */}
      </div>
      <div className="opacity" onClick={onToggle}></div>
    </div>
  );
};

export default BurgerMenu;

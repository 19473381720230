import "./radio.styles.scss";

const Radio = ({
  attribute,
  selectedOption,
  onChangeHandler,
  options,
  unit,
  size,
  name,
}) => {
  return (
    <div className="radio-component-container">
    <label className="radio-label">{name}:</label>
      <ul className="radio-container">
        {options.map((option) => (
          <div className="radio-button-container" style={{ width: size }} key={option}>
            <input
              type="radio"
              value={option}
              name={attribute}
              className="hidden"
              id={option}
              checked={selectedOption === `${option}`}
              onChange={onChangeHandler}
            />
            <label htmlFor={option} className="radio-button-label">
              {option}
              {unit}
            </label>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Radio;

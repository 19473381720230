import { Routes, Route } from "react-router-dom";
import Home from "./components/routes/home/home.component";
import Authentication from "./components/routes/authentication/authentication.component";
import Navigation from "./components/routes/navigation/navigation.component";
import Shop from "./components/shop/shop.component";
import Checkout from "./components/routes/checkout/checkout.component";
import ItemPage from "./components/routes/item-page/item-page.component";
import Contact from "./components/routes/contact/contact.component";
import NotFoundPage from "./components/routes/not-found/not-found.component";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="auth" element={<Authentication />} />
        {/* <Route path="shop" element={<Shop />} /> */}
        <Route path="shop/:title" element={<Shop />}/>
        <Route path="checkout" element={<Checkout />} />
        <Route path="items/:id" element={<ItemPage />} />
        <Route path="contact" element={<Contact />}/>
        <Route path="/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};


export default App;

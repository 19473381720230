import "./search-box.styles.scss";
import { ReactComponent as SearchIcon } from "../../assets/search-icon.svg";

const SearchBox = ({ text, onChangeHandler, onFocus }) => (
  <div className="search-box-container">
    <div className="box-icon-container">
      <input
        className={`search-box`}
        type="search"
        placeholder="Išči po trgovini"
        value={text}
        onChange={onChangeHandler}
        onFocus={onFocus}
      />
      <div className="search-icon">
        <SearchIcon />
      </div>
    </div>
  </div>
);

export default SearchBox;

import React from "react";
import "./checkout-item.styles.scss";
import QuantityInput from "../quantity-input/quantity-input.component";
import { CartContext } from "../../contexts/cart.context";
import { useContext } from "react";

const CheckoutItem = ({ item }) => {
  const { addItemToCart, removeItemFromCart } = useContext(CartContext);

  const onQuantityAddHandler = (event) => {
    addItemToCart(event, 1);
  };

  const onQuantityRemoveHandler = (event) => {
    if (event.quantity === 1) {
      removeItemFromCart(item);
    } else {
      addItemToCart(event, -1);
    }
  };

  return (
    <div className="checkout-item">
      <div className="checkout-item-image-container">
        <img src={item.imageUrl.replace("www","b2b")} alt="item" />
      </div>
      <div className="properties">
        <div className="main-properties">
          <span className="name">{item.name}</span>
          <div className="quantity-remove-container">
            {/* <span className="quantity">{quantity}</span> */}
            <div
              className="remove-button"
              onClick={() => removeItemFromCart(item)}
            >
              &#10005;
            </div>
          </div>
        </div>
        <div className="secondary-properties">
          <span className="props">
            {item.length ? <div>dolžina: {item.length}m, </div> : ""}
            {item.treatment ? <div>obdelava: {item.treatment}, </div> : ""}
            {item.pack ? <div>paket: {item.pack}m, </div> : ""}{" "}
            {item.group_10 ? <div>diameter: {item.group_10}mm, </div> : ""}
            {item.termination ? (
              <div>končne zanke: ${item.termination}, </div>
            ) : (
              ""
            )}
            {item.color ? <div>Barva: {item.color}, </div> : ""}
          </span>
          <div className="quantity-price-container">
            <QuantityInput
              onQuantityAddHandler={() => onQuantityAddHandler(item)}
              onQuantityRemoveHandler={() => onQuantityRemoveHandler(item)}
              quantity={item.quantity}
            />
            <div className="item-price">{(item.price*item.quantity).toFixed(2)}€</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutItem;

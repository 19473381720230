import React, { useContext, useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { CartContext } from "../../../contexts/cart.context";
import CheckoutItem from "../../checkout-item/checkout-item.component";
import "./checkout.styles.scss";
import Button from "../../button/button.component";
import FormInput from "../../form-input/form-input.component";
import { renderToStaticMarkup } from "react-dom/server";
import EmailItem from "../../email-item/email-item.component";
const Checkout = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cartTotal, setCartTotal] = useState(0);

  const handleSendInquiry = (event) => {
    event.preventDefault();

    if (!email) {
      alert("Prosimo vas, da vpišete svoj E-mail naslov!");
      return;
    }
    if (!phone) {
      alert("Prosimo vas, da vpišete svojo telefonsko številko!");
      return;
    }

    const message = `<div>${cartItems.map((item) => {
      return renderToStaticMarkup(<EmailItem item={item}/>);
    })}</div>`;

    const templateParams = {
      from_name: `${email}`,
      message: message,
      phone: `${phone}`,
      total_price: `${(cartTotal*0.8).toFixed(2)}`,
    };

    emailjs
      .send(
        "service_strik",
        "template_svn4Wc8",
        templateParams,
        "sFqXqX4jqL379JTq-"
      )
      .then((response) => {
        // alert("Inquiry sent successfully!");
        clearCart();
        setEmail("");
        setPhone("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        alert("Failed to send inquiry. Please try again.");
      });
  };

  useEffect(() => {
    const newCartTotal = cartItems.reduce(
      (total, cartItem) => total + cartItem.quantity * cartItem.price,
      0
    );
    setCartTotal(newCartTotal);
  }, [cartItems]);

  return (
    <div>
      <div className="checkout-page-container">
        <div className="items-container">
          {/* <h3>Izdelki v košarici</h3> */}
          <ul className="items-list" id="items-list">
            {cartItems.map((item, index) => (
              <li key={index}>
                {<CheckoutItem item={item} />}
              </li>
            ))}
          </ul>
          <div className={`${cartItems.length > 0 ?"" :"hidden"}`}>
          <hr className="divider-solid" />
          <div className="total-price">SKUPAJ: {cartTotal.toFixed(2)}€</div>
          <div className="reduced-price">SKUPAJ: {(cartTotal*0.8).toFixed(2)}€</div>
          </div>  
        </div>
        
        <div className="user-info">
          <h2>Osebni Podatki</h2>
          <div>
            Trenutno nam z naročilom le pošljete poizvedbo o želenih izdelkih.
            Prosimo vas, da izpolnete spodnja polja, da lahko čim prej stopimo v
            kontakt z vami in zaključimo naročilo.
          </div>
          <form onSubmit={handleSendInquiry}>
            <FormInput
              label="Email"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              value={email}
            />
            <FormInput
              label="Telefonska št."
              type="tel"
              required
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              value={phone}
            />

            <div className="buttons-container">
              <Button type="submit">Pošlji poizvedbo</Button>
            </div>
          </form>
          {/* <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          Telefon:
          <input
            type="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </label> */}
        </div>
      </div>
    </div>
  );
};

export default Checkout;

import React from "react";

const EmailItem = ({ item }) => {
  const { name, imageUrl, id, price, ...attributes } = item;

  return (
    <table
      style={{
        width: "100%",
        maxWidth: "1200px",
        borderCollapse: "collapse",
        padding: "10px 0",
        borderBottom: "1px solid #ddd",
      }}
      role="presentation"
    >
      <tbody>
        <tr>
          <td style={{ paddingRight: "15px", width: "180px" }}>
            <img
              src={imageUrl.replace("www","b2b")}
              alt={name}
              style={{
                width: "150px",
                height: "150px",
                display: "block",
              }}
            />
          </td>
          <td style={{ verticalAlign: "top", width: "fill"}}>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "5px",
              }}
            >
              {name}
            </div>
            {Object.keys(attributes).map((key, index) => (
              <div
                key={index}
                style={{
                  fontSize: "14px",
                  padding: "2px 0",
                }}
              >
                {`${key}: ${attributes[key]}`}
              </div>
            ))}
          </td>
          <td style={{ textAlign: "right", verticalAlign: "top", fontSize: "18px", fontWeight: "bold", width: "150px"}}>
            €{price}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmailItem;

const Contact = () => {
    return (
        <div>
            <h1>ŠTRIKARIJA - Vertikala s.p.</h1>
            <div>
                Email: info@strikarija.com
            </div>
            <div>
                Telefon: +386 40 893 377
            </div>
            <div></div>
        </div>
    )
}

export default Contact;
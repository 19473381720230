const SHOP_DATA = [{
  title: "Popkovine",
  show: true,
  items: [
    {
      title: "Samovarovalni kompleti",
      show: true,
      items: [
        {
          id: 142,
          show: true,
          name: "DYNACLIP",
          desc: "The dynamic rope sling with sewn endings for belaying safely.",
          imageUrl:
            "https://www.beal-planet.com/35182-large_default/dynaclip.jpg",
          images: [],
          basePrice: 21.57,
          length: [0.4, 0.5, 0.75],
          price: 1.7,
          attributes: {
            Diameter: "9 mm",
            Material: "Polyamide",
            Sport_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "DYNACLIP",
          },
        },
        {
          id: 143,
          show: true,
          name: "DYNACONNEXION 40-80CM",
          desc: "The dynamic rope sling with sewn endings for big wall climbing and mountaineering",
          imageUrl:
            "https://www.beal-planet.com/35409-large_default/dynaconnexion-40-80cm.jpg",
          images: [],
          basePrice: 0,
          price: 28.81,
          attributes: {
            Diameter: "8.6 mm",
            Material: "Polyamide",
            Length: "80cm",
            Sport_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynaconnexion",
            Breaking_load: "22 kN",
          },
        },
        {
          id: 144,
          show: true,
          name: "DYNADOUBLECLIP",
          desc: "The double dynamic rope lanyard with sewn endings for big wall climbing and mountaineering.",
          imageUrl:
            "https://www.beal-planet.com/35410-large_default/dynadoubleclip.jpg",
          images: [],
          basePrice: 0,
          price: 29.62,
          attributes: {
            Diameter: "9 mm",
            Material: "Polyamide",
            Length: "75cm",
            Sport_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynadoubleclip",
          },
        },
        {
          id: 145,
          show: true,
          name: "DYNAPARK 80CM x 80CM",
          desc: "A Y-shaped safety lanyard for adventure and acrobatic routes.",
          imageUrl:
            "https://www.beal-planet.com/35411-large_default/dynapark-80cm-x-80cm.jpg",
          images: [],
          basePrice: 0,
          price: 30.25,
          attributes: {
            Diameter: "9 mm",
            Material: "Polyamide",
            Length: "80cm",
            Sport_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "DYNAPARK",
          },
        },
        {
          id: 146,
          show: true,
          name: "EXPRESSO",
          desc: "The first double lanyard as light and compact as a single one. BEAL Innovation – Patent Pending.",
          imageUrl:
            "https://www.beal-planet.com/35829-large_default/expresso.jpg",
          images: [],
          basePrice: 0,
          price: 32.0,
          attributes: {
            Diameter: "9.5mm",
            Material: "Polyamide/Dyneema",
            Size: "35/75 cm",
            Sport_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Expresso",
            Color: "BLUE",
          },
        },
      ],
    },
    {
      title: "Varovala in Kljuke",
      show: true,
      items: [
        {
          id: 142,
          show: false,
          name: "VOLCANO 70cm",
          desc: "Lengths of rope with sewn ends for making self-locking knots.",
          imageUrl:
            "https://www.beal-planet.com/35559-large_default/volcano.jpg",
          images: [],
          basePrice: 0,
          length: [0.7],
          price: 47.55714285714286,
          group_10: ["12mm", "10mm", "8mm"],
          attributes: {
            Range: "Tree work line",
            Material: "Polyamide/Aramide",
            Professional_Products: "yes",
            Sheath_material: "Polyester Aramid",
            Rope_core: "Polyamide (PA)",
            Family: "VOLCANO",
            Color: "VOLCANO 8 mm x 70cm",
            Breaking_load: "18 kN",
          },
        },
        {
          id: 255,
          show: false,
          name: "VOLCANO 90cm",
          desc: "Lengths of rope with sewn ends for making self-locking knots.",
          imageUrl:
            "https://www.beal-planet.com/35559-large_default/volcano.jpg",
          images: [],
          basePrice: 0,
          length: [0.9],
          price: 47.55714285714286,
          group_10: ["12mm", "10mm", "8mm"],
          attributes: {
            Range: "Tree work line",
            Material: "Polyamide/Aramide",
            Professional_Products: "yes",
            Sheath_material: "Polyester Aramid",
            Rope_core: "Polyamide (PA)",
            Family: "VOLCANO",
            Color: "VOLCANO 8 mm x 70cm",
            Breaking_load: "18 kN",
          },
        },
        {
          id: 143,
          show: true,
          name: "DYNAPRO AIR HOOK",
          desc: "Fall arrest lanyard with integrated energy absorber which complies with the EN 355 and EN 362 standards.",
          imageUrl:
            "https://www.beal-planet.com/35565-large_default/dynapro-air-hook.jpg",
          images: [],
          basePrice: 117.75,
          length: [1.0, 1.5, 2.0],
          price: 4,
          attributes: {
            Diameter: "11 mm",
            Material: "Polyamide",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynapro Air",
          },
        },
        {
          id: 144,
          show: false,
          name: "DYNAPRO AIR V HOOK",
          desc: "A V-shaped fall arrest lanyard with integrated energy absorber which meets EN 355 and EN 362 standards",
          imageUrl:
            "https://www.beal-planet.com/35568-large_default/dynapro-air-v-hook.jpg",
          images: [],
          basePrice: 0,
          length: [1.0, 1.5, 2.0],
          price: 155.92,
          attributes: {
            Diameter: "11 mm",
            Material: "Polyamide",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynapro Air",
          },
        },
        {
          id: 145,
          show: true,
          name: "DYNAPRO",
          desc: "A single professional dynamic rope lanyard for restraint at the workstation.",
          imageUrl:
            "https://www.beal-planet.com/35574-large_default/dynapro.jpg",
          images: [],
          basePrice: 14.82,
          length: [1.0, 1.5, 0.6],
          price: 8,
          attributes: {
            Diameter: "11 mm",
            Material: "Polyamide",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "DYNAPRO",
            Breaking_load: "22 kN",
          },
        },
        {
          id: 146,
          show: true,
          name: "ADJUST AIR",
          desc: "",
          imageUrl:
            "https://www.beal-planet.com/35575-large_default/adjust-air.jpg",
          images: [],
          basePrice: 101.48,
          length: [2.0, 3.0, 5.0],
          price: 3.84,
          attributes: {
            Material: "Polyamide",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "ADJUST AIR",
            Breaking_load: "100 kg",
          },
        },
        {
          id: 147,
          show: true,
          name: "ABSORB AIR",
          desc: "Webbing construction energy absorber for fall protection",
          imageUrl:
            "https://www.beal-planet.com/35589-large_default/absorb-air.jpg",
          images: [
            "https://www.beal-planet.com/35589-large_default/absorb-air.jpg",
            "https://www.beal-planet.com/35590-large_default/absorb-air.jpg",
            "https://www.beal-planet.com/35591-large_default/absorb-air.jpg",
          ],
          basePrice: 0,
          price: 39.9,
          attributes: {
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "ABSORB AIR",
          },
        },
        {
          id: 148,
          show: true,
          name: "DYNAPRO 8",
          desc: "A dynamic rope lanyard with adjustable length.",
          imageUrl:
            "https://www.beal-planet.com/35592-large_default/dynapro-8.jpg",
          images: [
            "https://www.beal-planet.com/35592-large_default/dynapro-8.jpg",
            "https://www.beal-planet.com/35593-large_default/dynapro-8.jpg",
            "https://www.beal-planet.com/35594-large_default/dynapro-8.jpg",
          ],
          basePrice: 0,
          price: 27.77,
          attributes: {
            Diameter: "12 mm",
            Material: "Polyamide",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynapro 8",
            Breaking_load: "22 kN",
          },
        },
        {
          id: 149,
          show: true,
          name: "DYNAPRO V 30/60",
          desc: "Asymmetrical V lanyard in dynamic rope for professionals.",
          imageUrl:
            "https://www.beal-planet.com/35595-large_default/dynapro-v-30-60.jpg",
          images: [],
          basePrice: 0,
          price: 30.8,
          attributes: {
            Diameter: "11 mm",
            Material: "Polyamide",
            Length: "60cm",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "Dynapro V",
            Breaking_load: "22 kN",
          },
        },
        {
          id: 150,
          show: true,
          name: "NEXUS I 60cm",
          desc: "A 60cm multipurpose lanyard for temporary anchoring.",
          imageUrl:
            "https://www.beal-planet.com/35597-large_default/nexus-i-60cm.jpg",
          images: [],
          basePrice: 0,
          price: 13.49,
          attributes: {
            Diameter: "20 mm",
            Length: "60cm",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "NEXUS",
            Color: "YELLOW",
            Breaking_load: "22 kN",
          },
        },
        {
          id: 151,
          show: true,
          name: "NEXUS V 2x80cm",
          desc: "A 80cm multipurpose lanyard for temporary anchoring.",
          imageUrl:
            "https://www.beal-planet.com/35598-large_default/nexus-v-2x80cm.jpg",
          images: [],
          basePrice: 0,
          price: 19.57,
          attributes: {
            Diameter: "20 mm",
            Length: "80cm",
            Professional_Products: "yes",
            Sheath_material: "Polyamide (PA)",
            Rope_core: "Polyamide (PA)",
            Family: "NEXUS",
            Color: "YELLOW",
            Breaking_load: "22 kN",
          },
        },
      ],
    },
  ],
},
];

export default SHOP_DATA;

import "./color-picker.styles.scss";

const ColorPicker = ({ colors, selectedOption, onChangeHandler }) => {
  return (
    <div className="color-box-container">
      {colors.map(({ colorHex, name }) => (
        <div className="color-box" key={name}>
          <input
            type="radio"
            value={name}
            id={name}
            checked={selectedOption === `${name}`}
            onChange={onChangeHandler}
            name="color"
            className="radio-color"
            readOnly
          />
          <label
            htmlFor={name}
            className="color-box-label"
            style={{
              backgroundColor: `${colorHex}`,
            }}
          ></label>
        </div>
      ))}
    </div>
  );
};

export default ColorPicker;

/* 
<h1>Radio Color Picker</h1>
<input type="radio" name="color" id="red" value="red" />
<label for="red"><span class="red"></span></label>

<input type="radio" name="color" id="green" />
<label for="green"><span class="green"></span></label>

<input type="radio" name="color" id="yellow" />
<label for="yellow"><span class="yellow"></span></label>

<input type="radio" name="color" id="olive" />
<label for="olive"><span class="olive"></span></label>

<input type="radio" name="color" id="orange" />
<label for="orange"><span class="orange"></span></label>

<input type="radio" name="color" id="teal" />
<label for="teal"><span class="teal"></span></label>

<input type="radio" name="color" id="blue" />
<label for="blue"><span class="blue"></span></label>

<input type="radio" name="color" id="violet" />
<label for="violet"><span class="violet"></span></label>

<input type="radio" name="color" id="purple" />
<label for="purple"><span class="purple"></span></label>

<input type="radio" name="color" id="pink" />
<label for="pink"><span class="pink"></span></label>
*/

import { useContext, Fragment } from "react";
import { useParams } from "react-router-dom";

import { CategoriesContext } from "../../contexts/categories.context";
import ProductCard from "../product-card/product-card.component";
import DocumentTitle from "../../utils/document-title.utils";

import "./shop.styles.scss";

const Shop = () => {
  const { title } = useParams();
  const { categoriesMap } = useContext(CategoriesContext);


  const newTitle = title.replaceAll("-", " ");
  if (newTitle === "shop") {
    DocumentTitle("Vsi izdelki");
  } else {
    DocumentTitle(newTitle);
  }



  const renderProductCards = () => {
    return Object.keys(categoriesMap).flatMap((key) => {
      if ((key === newTitle || newTitle === "shop") && categoriesMap[key].show) {
        return categoriesMap[key].items.flatMap((category) =>
          category.items.map((item) => item.id && <ProductCard key={item.id} product={item} />)
        );
      } else {
        return categoriesMap[key].items.flatMap((category) => {
          if ((category.title === newTitle || newTitle === "shop") && category.show) {
            return category.items.map((item) => item.id && <ProductCard key={item.id} product={item} />);
          }
          return [];
        });
      }
    });
  };

  return (
    <Fragment><div className="shop-container">
    <div className="section-name">{newTitle.toLocaleUpperCase()}</div>
      <div className="products-container">
        {renderProductCards()}
      </div>
      </div>
    </Fragment>
  );
};

export default Shop;

import './cart-icon.styles.scss'

import { useContext } from 'react'

import { CartContext } from '../../contexts/cart.context'

import { ReactComponent as ShoppingIcon } from '../../assets/cart-icon.svg'

const CartIcon = () => {
    const {cartCount} = useContext(CartContext);
    return (
        <div className='cart-icon-container'>
            <ShoppingIcon className='shopping-icon' />
            <span className='item-count'>{cartCount}</span>
        </div>
    )
}

export default CartIcon;
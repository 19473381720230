import { useParams } from "react-router-dom";
import Radio from "../../radio/radio.component";
import { useState, useContext, useEffect } from "react";
import ColorPicker from "../../color-picker/color-picker.component";
import Button from "../../button/button.component";
import QuantityInput from "../../quantity-input/quantity-input.component";
import { CategoriesContext } from "../../../contexts/categories.context";
import { CartContext } from "../../../contexts/cart.context";
import { Helmet } from "react-helmet";

import "./item-page.styles.scss";
import ItemSpecs from "../../item-specs/item-specs.component";
import Gallery from "../../gallery/galery.component";


const ItemPage = () => {
  const { id } = useParams();
  const { addItemToCart } = useContext(CartContext);
  const { categoriesMap } = useContext(CategoriesContext);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [item, setItem] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null)
  const [multipliers, setMultipliers] = useState([])
  const [multiplier, setMultiplier] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    const findItemById = () => {
      let foundItem = null;
      outerLoop: for (const mainCategory of Object.values(categoriesMap)) {
        for (const subCategory of mainCategory.items) {
          foundItem = subCategory.items.find(
            (product) => product.name === id.replaceAll("_", " ")
          );
          if (foundItem) break outerLoop;
        }
      }
      return foundItem;
    };

    if (categoriesMap && Object.keys(categoriesMap).length > 0) {
      const foundItem = findItemById();
      setItem(foundItem);

      if (foundItem) {
        const initialOptions = {};
        if (foundItem.name) initialOptions.name = foundItem.name;
        if (foundItem.id) initialOptions.id = foundItem.id;
        if (foundItem.imageUrl) initialOptions.imageUrl = foundItem.imageUrl;
        if (foundItem.length && foundItem.length.length !== 0) initialOptions.length = `${foundItem.length[0]}`;
        if (foundItem.treatment && foundItem.treatment.length !== 0) initialOptions.treatment = `${foundItem.treatment[0].name}`;
        if (foundItem.price) initialOptions.price = foundItem.price;
        if (foundItem.size && foundItem.size.length !== 0) initialOptions.size = `${foundItem.size[0]}`;
        // if (foundItem.price) initialOptions.actualPrice = foundItem.price;
        if (foundItem.treatment) setMultipliers(foundItem.treatment);
        if (foundItem.colors && foundItem.colors.length !== 0) initialOptions.color = `${foundItem.colors[0].name}`;
        if (foundItem.pack && foundItem.pack.length !== 0) initialOptions.pack = `${foundItem.pack[0]}`;
        if (foundItem.termination && foundItem.termination.length !== 0) initialOptions.termination = `${foundItem.termination[0]}`;
        if (foundItem.group_10 && foundItem.group_10.length !== 0) initialOptions.group_10 = `${foundItem.group_10[0]}`
        if (foundItem.capacity && foundItem.capacity.length !== 0) initialOptions.capacity = `${foundItem.capacity[0]}`
        if (foundItem) setSelectedImage(foundItem.imageUrl);

        setSelectedOptions(initialOptions);
      }
    }
  }, [categoriesMap, id]);

  const onQuantityAddHandler = () => {
    setQuantity(quantity + 1);
  }

  const onQuantityRemoveHandler = () => {
    if (quantity !== 1) {
      setQuantity(quantity - 1);
    }
  }

  const onRadioChangeHandler = (event) => {
    const { name, value } = event.target;

    if (name === 'treatment') {
      setMultiplier(multipliers.find((product) => product.name === value).multiplier)
    }


    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };



  const addProductToCart = () => {
    var newPrice = (selectedOptions.price*multiplier* (item.length && item.length.length > 1 ?selectedOptions.length :1)).toFixed(2);
    const updatedOptions = {
      ...selectedOptions,
      price: newPrice,
    };
    
    addItemToCart(updatedOptions, quantity);}

  if (!item) {
    return <div>Loading...</div>;
  }

  const attributes = Object.keys(item.attributes).map(key => (
    item.attributes[key]
)).join(', ');
  return (
    <>
    <Helmet>
    <title>{item.name}</title>
      <meta name="description" content={`${item.desc}`}/>
      <meta name="keywords" content={`beal, Beal, sports climbing, heights, working on heights, ${item.name}, ${item.desc}, ${attributes}`} />
      <link rel="canonical" href={`https://strikarija.com/items/${id}`}/>
    </Helmet>    
    <div className="item-page-container">
      <div className="gallery-attributes-container">
        <div className="gallery-container">
          {item.images.length > 0 && <Gallery setSelectedImage={setSelectedImage} item={item}/>}
          <div className="main-image-container">
            <img src={selectedImage.replace("www", "b2b")} alt={item.name} />
          </div>
        </div>
        <div className="attributes-container">
          <h1 className="title">{item.name}</h1>
          <p className="desc">{item.desc}</p>
          <h2 className="price">Cena: {((item.basePrice ?item.basePrice : 0) + selectedOptions.price*quantity*multiplier* (item.length && item.length.length > 1 ?selectedOptions.length :1)).toFixed(2)}€</h2>
          <hr className="divider-solid" />
          {item.length && item.length.length > 1 && (
            <Radio
              selectedOption={selectedOptions.length}
              options={item.length}
              onChangeHandler={onRadioChangeHandler}
              unit="m"
              attribute="length"
              name="Dolžina"
            />)}
            {item.size && item.size.length > 1 && (
            <Radio
              selectedOption={selectedOptions.size}
              options={item.size}
              onChangeHandler={onRadioChangeHandler}
              unit=""
              attribute="size"
              name="Velikost"
            />)}
          {item.treatment && item.treatment.length > 1 && (
            <Radio
              selectedOption={selectedOptions.treatment}
              options={item.treatment.map((key) => {return key.name})}
              onChangeHandler={onRadioChangeHandler}
              attribute="treatment"
              size="100px"
              name="Obdelava"
            />
          )}
          {item.pack && item.pack.length > 1 && (
            <Radio
              selectedOption={selectedOptions.pack}
              options={item.pack}
              onChangeHandler={onRadioChangeHandler}
              attribute="pack"
              name="Paket Vrvi"
              unit="m"
            />
          )}
          {item.group_10 && item.group_10.length > 1 && (
            <Radio
              selectedOption={selectedOptions.group_10}
              options={item.group_10}
              onChangeHandler={onRadioChangeHandler}
              attribute="group_10"
              name="Diameter"
              unit="mm"
            />
          )}<Helmet>
    <title>{item.name}</title>
      <meta name="description" content={`${item.desc}`}/>
      <meta name="keywords" content={`${item.name} ${item.desc}${item.attributes ?? item.attributes}`} />
      <link rel="canonical" href={`https://strikarija.com/items/${id}`}/>
    </Helmet>    
          {item.termination && item.termination.length > 0 && (
            <Radio
              selectedOption={selectedOptions.termination}
              options={item.termination}
              onChangeHandler={onRadioChangeHandler}
              attribute="termination"
              name="Končne zanke"
              size="180px"
              unit=""
            />
          )}
          {item.capacity && item.capacity.length > 1 && (
            <Radio
              selectedOption={selectedOptions.capacity}
              options={item.capacity}
              onChangeHandler={onRadioChangeHandler}
              attribute="capacity"
              name="Širina"
              unit=""
            />
          )}
          {item.colors && item.colors.length > 0 && (
            <div className="color-picker">
              <label className="color-picker-label">Barva: </label>
              <div className="color-picker-component">
                <ColorPicker
                  colors={item.colors}
                  onChangeHandler={onRadioChangeHandler}
                  selectedOption={selectedOptions.color}
                />
              </div>
            </div>
          )}
          <div className="button-quantity-container">
          <div className="quantity">
            <label className="quantity-label">Količina: </label>
            <QuantityInput  onQuantityAddHandler={onQuantityAddHandler} onQuantityRemoveHandler={onQuantityRemoveHandler} quantity={quantity} />
          </div>
            <Button onClick={addProductToCart}>Dodaj v košarico</Button>
          </div>
        </div>
      </div>
      <hr className="divider-solid" />
      <h2>SPECIFIKACIJE</h2>
      <div>{item.attributes && <ItemSpecs attributes={item.attributes}/>}</div>
    </div></>

  );
};

export default ItemPage;

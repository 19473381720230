import './galery.styles.scss'

const Gallery = ({item, setSelectedImage}) => {

    return (<div className="gallery" id="gallery">
        {item.images.map((key) => (<div className="image-container"><img src={key.replace("www", "b2b")} alt={item.name} key={key } onClick={() => setSelectedImage(key)}/></div>))}
       </div>)
        
}

export default Gallery;
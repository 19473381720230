import "./quantity-input.styles.scss";

const QuantityInput = ({ quantity, onQuantityAddHandler, onQuantityRemoveHandler }) => {


  return (
    <div className="checkout-item-container">
      <span className="quantity">
        <span className="value">{quantity}</span>
        <div className="arrow-container">
        <div className="arrow-up" onClick={onQuantityAddHandler}>
          &#x25B2;
        </div>
        <div className="arrow-down" onClick={onQuantityRemoveHandler}>
        &#x25BC;
        </div>
        </div>
      </span>
    </div>
  );
};

export default QuantityInput;

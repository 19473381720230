import DocumentTitle from "../../../utils/document-title.utils";
import './home.styles.scss'

const Home = () => {

  DocumentTitle("Štrikarija - Spletna trgovina za delo na višini");
  return (
    <div className="home-container">
      <div className="welcome-container">
        <div className="welcome-speech">
          Veseli nas, da ste se ustavili na naši spletni trgovini Štrikarija. Smo
          nova pravkar zagnana spletna trgovina za prodajo opreme s področja
          alpinizma, jamarstva, arboristike, dela na višini, kanjoninga in
          dvoranskega plezanja, ki je izdelano od svetovno znanega proizvajalca
          Beal.
          <br />
          <br />
          Vzami si čas in se sprehodi po spletni trgovini, ter nam podaj kakšen
          "feed back". Prav tako vam bomo z veseljem odgovorili na kakršnokoli
          zastavljeno vprašanje.
        </div>
      </div>
    </div>
  );
};

export default Home;

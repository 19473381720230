import { createContext, useEffect, useState } from "react";

// Utility function to check if two items are the same based on selected options
const checkOptionsEqual = (options1, options2) => {
  for (let key of Object.keys(options1)) {
    if (options1[key] !== options2[key] && key !== "quantity") {
      return false;
    }
  }
  return true;
};

// Function to add an item to the cart or update its quantity if it already exists
const addCartItem = (cartItems, productToAdd, itemQuantity) => {
  const existingItem = cartItems.find((cartItem) =>
    checkOptionsEqual(cartItem, productToAdd)
  );

  if (existingItem) {
    return cartItems.map((cartItem) =>
      checkOptionsEqual(cartItem, productToAdd)
        ? { ...cartItem, quantity: cartItem.quantity + itemQuantity }
        : cartItem
    );
  }

  return [...cartItems, { ...productToAdd, quantity: itemQuantity }];
};

const removeCartItem = (cartItems, cartItemToRemove) => {
  return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id);
};

export const CartContext = createContext({
  cartItems: [],
  addItemToCart: () => {},
  cartCount: 0,
});

export const CartProvider = ({ children }) => {
  // Initialize cartItems state with items from localStorage if available
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // Calculate the total count of items in the cart
    const newCartCount = cartItems.reduce(
      (total, cartItem) => total + cartItem.quantity,
      0
    );
    setCartCount(newCartCount);

    // Save the cart items to localStorage
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const addItemToCart = (productToAdd, quantity) => {
    setCartItems((prevCartItems) =>
      addCartItem(prevCartItems, productToAdd, quantity)
    );
  };

  const removeItemFromCart = (cartItemToRemove) => {
    setCartItems(removeCartItem(cartItems, cartItemToRemove));
  };

  const clearCart = () => {
    setCartItems([]);
  }

  const value = { addItemToCart, removeItemFromCart, clearCart, cartItems, cartCount };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

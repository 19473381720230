import "./footer.styles.scss";
import FacebookLogo from "../../assets/Facebook_Logo.png";
import InstagramLogo from "../../assets/instagram_logo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-info">
        <h3>KONTAKT</h3>
        <p>mail: info@strikarija.com</p>
        <p>telefon: 040893377</p>
      </div>
      <div className="footer-contact">
        <a
          href="https://www.facebook.com/profile.php?id=61564912073514"
          target="_blank"
          rel="noreferrer"
        >
          <img src={FacebookLogo} alt="facebook-page" />
        </a>
        <a
          href="https://www.instagram.com/strik.arija?igsh=dDNxbTRubzludGJu"
          target="_blank"
          rel="noreferrer"
        >
          <img src={InstagramLogo} alt="instagram-page" />
        </a>
      </div>
    </div>
  );
};

export default Footer;

import { createContext, useState, useEffect } from "react";

import {
  onAuthStateChangedListener,
  createUserDocumentFromAuth,
} from "../utils/firebase/firebase.utils";

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: () => null,
  isAdmin: false,
  setIsAdmin: () => false,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const value = { currentUser, setCurrentUser, isAdmin };

  useEffect(() => {

    const checkIsAdmin = async (user) => {
      try {
        const userData = await createUserDocumentFromAuth(user);
        setIsAdmin(userData.admin);
      }
      catch (error) {
      }
    };

    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        checkIsAdmin(user);
      }
      else {
        setIsAdmin(false);
      }
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
